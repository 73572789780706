/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:46:32
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 19:53:32
 */
import Career from './career.vue'
export default Career
